$primary: #064985;
$secondary: #18427d;
$accent: #DC8449;
$dark: #00348d;
$darkComplimentary: #c192f0;
$bg1: #f0f4f9;
$bg2: #f5f4f0;
$lightPrimary: #2566c1;
$greyPrimary: #d5e3f6;
$green: #00b32a;
